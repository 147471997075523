// Should be imported only by analytics.js to prevent importing multiple modules
export const _EVENT_NAMES = {
  ADD_CENTERPIECE_TAPPED: 'Add Centerpiece Tapped',
  ADD_PHOTOS_TAPPED: 'Add Photos Tapped',
  ADD_PHOTOS_TO_COLLAGE_POPUP_OPENED: 'add Photos To Collage Popup Opened',
  ADD_PROMO_CODE_TAPPED: 'Add Promo Code Tapped',
  ADJUST_PHOTO_TAPPED: 'Adjust Photo Tapped',
  ART_PICKER_ARTWORK_ADDED: 'Art Picker: Artwork Added',
  ART_PICKER_ARTWORK_SELECTED: 'Art Picker: Artwork Selected',
  ART_PICKER_ARTWORK_UNSELECTED: 'Art Picker: Artwork Unselected',
  ART_PICKER_ARTWORK_VIEWED: 'Art Picker: Artwork Viewed',
  ART_PICKER_CATEGORY_TAPPED: 'Art Picker: Category Tapped',
  ART_PICKER_NEXT_TAPPED: 'Art Picker: Next Tapped',
  ART_PICKER_OPENED: 'Art Picker Opened',
  BLOG_ARTICLE_TAPPED: 'Blog: Article Tapped',
  BLOG_ARTICLE_BREADCRUMBS_TAPPED: 'Blog: Article Breadcrumbs Tapped',
  BLOG_POPULAR_ARTICLE_TAPPED: 'Blog: Popular Article Tapped',
  BLOG_TAG_TAPPED: 'Blog: Tag Tapped',
  BOOK_INTRO_HOMEPAGE_FAQ_VIEWED:
    'Book Intro: Frequently Asked Questions Viewed',
  BOOK_INTRO_HOMEPAGE_FAQ_TAPPED: 'Book Intro: FAQ - Question Tapped',
  BOOKS_PAGES_BUTTON_TAPPED: 'Books: Pages Button Tapped',
  BOOKS_ORDER_PAGES_CLOSED: 'Books: Order Pages: Closed',
  BOOKS_ORDER_PAGES_DONE: 'Books: Order Pages: Done Tapped',
  BOOKS_ORDER_PAGES_PAGE_TAPPED: 'Books: Order Pages: Page Tapped',
  BOOKS_ORDER_PAGES_SORT_TAPPED: 'Books: Order Pages: Sort Tapped',
  BOOKS_COVER_BUTTON_TAPPED: 'Books: Cover Button Tapped',
  BOOKS_COVER_EDIT_DONE_TAPPED: 'Books: Cover Edit: Done Tapped',
  BOOKS_COVER_EDIT_PHOTO_TAPPED: 'Books: Cover Edit: Photo Tapped',
  BOOKS_COVER_EDIT_TITLE_EDIT_STARTED: 'Books: Cover Edit: Title Edit Started',
  BOOKS_COVER_EDIT_TITLE_REMOVED: 'Books: Cover Edit: Title Removed',
  BOOKS_COVER_TITLE_TAPPED: 'Books: Cover Title Tapped',
  BOOKS_COVER_EDIT_TOGGLE_TAPPED: 'Books: Cover Edit: Toggle Tapped',
  BOOKS_COVER_EDIT_TEXT_LIMIT_REACHED: 'Books: Cover Edit: Text Limit Reached',
  BOOKS_DELETE_BOOK_APPROVED: 'Books: Delete Book Approved',
  BOOKS_DELETE_BOOK_DISMISSED: 'Books: Delete Book Dismissed',
  BOOKS_DELETE_BOOK_TAPPED: 'Books: Delete Book Tapped',
  BOOKS_DELETE_PAGE_TAPPED: 'Books: Delete Page Tapped',
  BOOKS_EXCEEDING_PHOTOS_DONE_TAPPED: 'Books: Exceeding Photos: Done Tapped',
  BOOKS_FEEDBACK_SUBMITTED: 'Books: Feedback Submitted',
  BOOKS_GIVE_FEEDBACK_TAPPED: 'Books: Give Feedback Tapped',
  BOOKS_HOME_PAGE_CTA_TAPPED: 'Books: Homepage CTA Tapped',
  BOOKS_HOW_TO_ARRANGE_TAPPED: 'Books: How To Arrange Tapped',
  BOOKS_LOW_RES_IMAGE_REMOVED: 'Books: Low Res Image Removed',
  BOOKS_LOW_RES_POPUP_APPROVED: 'Books: Low Res Popup Approved',
  BOOKS_LOW_RES_WARNING_DISPLAYED: 'Books: Low Res Warning Displayed',
  BOOKS_LOW_RES_WARNING_TAPPED: 'Books: Low Res Warning Tapped',
  BOOKS_MINIMUM_PAGES_REACHED: 'Books: Minimum Pages Reached',
  BOOKS_MORE_ACTIONS_TAPPED: 'Books: More Actions Tapped',
  BOOKS_PAGE_LIMIT_REACHED: 'Books: Page Limit Reached',
  BOOKS_PAGE_MOVED: 'Books: Page Moved',
  BOOKS_PAGE_SWIPED: 'Books: Page Swiped',
  BOOKS_PAGE_TAPPED: 'Books: Page Tapped',
  BOOKS_PAGING_SCROLLBAR_SCROLLED: 'Books: Paging Scrollbar Scrolled',
  BOOKS_PHOTO_CROPPED: 'Books: Photo Cropped',
  BOOKS_SIZE_DRAWER_TAPPED: 'Books: Size Drawer Tapped',
  BOOKS_SIZE_DRAWER_SIZE_TAPPED: 'Books: Size Drawer Size Tapped',
  BOOKS_SIZE_DRAWER_DONE_TAPPED: 'Books: Size Drawer Done Tapped',
  BOOKS_USE_AS_COVER_TAPPED: 'Books: Use as Cover Tapped',
  BUSINESS_CONTACT_FORM_SUBMITTED: 'Business: Contact Form Submitted',
  BUSINESS_TAB_TAPPED: 'Business: Tab Tapped',
  CART_RESTORED: 'Cart Restored',
  CHANGE_PROMO_CODE_TAPPED: 'Change Promo Code Tapped',
  CHECKOUT_CTA_LOADED: 'Checkout CTA Loaded',
  CHECKOUT_ITEM_COUNTER_TAPPED: 'Checkout: Item Counter Tapped',
  CHECKOUT_TAPPED: 'Checkout Tapped',
  CHECKOUT_PRICING_ERROR_SHOWN: 'Checkout Pricing Error',
  CHECKOUT_PRICING_ERROR_REFRESH_CLICKED: 'Checkout Pricing Refresh Clicked',
  CHECKOUT_SHEET_OPENED: 'Checkout Sheet Opened',
  GIFT_CARD_ADD_EMAIL_TAPPED: 'Gift Card - Add Email Tapped',
  GIFT_CARD_CHANGE_TYPE_TAPPED: 'Gift Card - Change Type Tapped',
  GIFT_CARD_CUSTOM_VALUE_ENTERED: 'Gift Card - Custom Value Entered',
  GIFT_CARD_DESIGN_TAPPED: 'Gift Card - Design Tapped',
  GIFT_CARD_FIELD_ADDED: 'Gift Card - Field Added',
  GIFT_CARD_SCROLLED_TO_BOTTOM: 'Gift Card - Scrolled To Bottom',
  GIFT_CARD_VALUE_TAPPED: 'Gift Card - Value Tapped',
  CHECKOUT_SHIPPING_ADDRESS_ADDED: 'Checkout - Shipping Address Added',
  CHECKOUT_GIFT_CARD_EMAIL_ADDED: 'Checkout - Gift Card Email Added',
  CHECKOUT_GIFT_CARD_REMOVE_CANCELLED: 'Checkout - Gift Card Remove Cancelled',
  CHECKOUT_GIFT_CARD_REMOVE_CONFIRMED: 'Checkout - Gift Card Remove Confirmed',
  CHECKOUT_GIFT_CARD_REMOVE_TAPPED: 'Checkout - Gift Card Remove Tapped',
  CHECKOUT_MIXED_MATERIALS_TAPPED: 'Checkout - Mixed Materials Tapped',
  COLLAGE_CREATED: 'Collage Created',
  COLLAGE_ADD_PHOTOS_TAPPED: 'Collage Add Photos Tapped',
  COLLAGE_CROPPED: 'Collage Cropped',
  COLLAGE_LAYOUT_CHANGED: 'Collage Layout Changed',
  COLLAGE_LAYOUT_DRAWER_TAPPED: 'Collage Layout Drawer Tapped',
  COLLAGE_LAYOUT_DRAWER_LAYOUT_TAPPED: 'Collage Layout Drawer: Layout Tapped',
  COLLAGE_LAYOUT_DRAWER_DONE_TAPPED: 'Collage Layout Drawer: Done Tapped',
  COLLAGE_TEXT_TAPPED: 'Collage Text Tapped',
  COLLAGE_TEXT_STYLE_SELECTED: 'Collage Text Style Selected',
  COLLAGE_TEXT_DRAWER_TAPPED: 'Collage Text Drawer Tapped',
  COLLAGE_TEXT_DRAWER_DONE_TAPPED: 'Collage Text Drawer: Done Tapped',
  INCOMPLETE_COLLAGE_POPUP_OPENED: 'Incomplete Collage Popup Opened',
  INCOMPLETE_COLLAGE_POPUP_APPROVED: 'Incomplete Collage Popup Approved',
  INCOMPLETE_COLLAGE_POPUP_DISMISSED: 'Incomplete Collage Popup Dismissed',
  FEATURE_FORCED: 'Feature Forced',
  COLLAGE_PHOTO_TAPPED: 'Collage Photo Tapped',
  DESIGNER_PAGE_VIEW: 'Designer Page View',
  DISCOVERY_PAGE_VIEW: 'Discovery Page View',
  EMPTY_TILE_TAPPED: 'Empty Tile Tapped',
  EXCEEDING_PHOTOS_DONE: 'Exceeding Photos Modal: Done Tapped',
  EXCEEDING_PHOTOS_VIEW: 'Exceeding Photos Modal View',
  EXCELLENT_QUALITY_BANNER_SHOWN: 'Excellent Quality Banner Shown',
  EXCELLENT_QUALITY_BANNER_DISMISSED: 'Excellent Quality Banner Dismissed',
  STICKY_EXPERIMENTS_APPLIED: 'Sticky Experiments Applied',
  GALLERY_WALLS_FOOTER_TAPPED: 'Gallery Walls Footer Tapped',
  IOS_UPLOAD_COMMUNICATION_DISPLAYED: 'IOS Upload Communication Displayed',
  LOGIN_EMAIL_ENTERED: 'EmbeddedLogin - Email Entered',
  LOGIN_EMAIL_CODE_ENTERED: 'EmbeddedLogin - Email Code Entered',
  LOGIN_ERROR: 'Login Error',
  LOGIN_OR_SIGN_UP_DIALOG_OPENED: 'Login or Sign Up Dialog Opened',
  LOGIN_SOCIAL_LOGIN_GOOGLE:
    'EmbeddedLogin - Social EmbeddedLogin Selected - Google',
  LOGIN_SOCIAL_LOGIN_FACEBOOK:
    'EmbeddedLogin - Social EmbeddedLogin Selected - Facebook',
  LOGIN_SOCIAL_LOGIN_APPLE:
    'EmbeddedLogin - Social EmbeddedLogin Selected - Apple',
  LOW_RESOLUTION_REVIEW_AND_REPLACE_TAPPED:
    'Low Resolution - Review and Replace Tapped',
  LOW_RESOLUTION_WARNING_CREATED: 'Low Resolution - Warning Created',
  LOW_RESOLUTION_WARNING_DISPLAYED: 'Low Resolution - Warning Displayed',
  LOW_RESOLUTION_WARNING_TAPPED: 'Low Resolution - Warning Tapped',
  FACES_CROP_WARNING_REVIEW_TAPPED: 'Faces Cropped - Review Tapped',
  FACES_CROP_WARNING_CREATED: 'Faces Cropped - Warning Created',
  FACES_CROP_WARNING_DISPLAYED: 'Faces Cropped - Warning Displayed',
  FACES_CROP_WARNING_TAPPED: 'Faces Cropped - Warning Tapped',
  TILE_WARNING_DISMISSED: 'Tile Warning Dismissed',
  MENU_OPENED: 'Menu Opened',
  NAME_CAPTURED: 'Name Captured',
  OS_PICKER_OPENED: 'OS Picker Opened',
  ORDER_COUNTRY_RESTRICTION_POPUP_SHOWN:
    'Order Country Restriction Popup Shown',
  ORDER_COUNTRY_RESTRICTION_POPUP_CLOSED:
    'Order Country Restriction Popup Closed',
  PAGE_FULLY_SCROLLED: 'Page Fully Scrolled',
  PHOTOS_UPLOADED: 'Photos Uploaded',
  PHOTOS_UPLOADED_TO_COLLAGE: 'Photos Uploaded To Collage',
  PHOTO_PICKED: 'Photo Picked',
  PHOTO_REMOVED: 'Photo Removed',
  PHOTO_TAPPED: 'Photo Tapped',
  PHOTO_REPLACED: 'Photo Replaced',
  PICK_PHOTOS_TAPPED: 'Pick Photos Tapped',
  PRODUCT_PAGE_CARD_VIEWED: 'Product Page: Card Viewed',
  PRODUCT_PAGE_MINIMIZED_TAPPED: 'Product Page: Minimize Tapped',
  PRODUCT_PAGE_MORE_INFO_TAPPED: 'Product Page: More Info Tapped',
  TOAST_ACTION_TAPPED: 'Toast Action Tapped',
  TOAST_DISPLAYED: 'Toast Displayed',
  TOAST_DISMISSED: 'Toast Dismissed',
  CREATE_COLLAGE_TAPPED: 'Create Collage Tapped',
  PRODUCT_CARD_TAPPED: 'Product Card Tapped',
  PRODUCT_CARD_VIEWED: 'Product Card Viewed',
  PROMO_CODE_ADDED: 'Promo Code Added',
  PROMO_CODE_ALREADY_REGISTERED: 'Promo Code Already Registered',
  PROMO_CODE_CHANGED: 'Promo Code Changed',
  PROMO_CODE_SELECTED: 'Promo Code Selected',
  BACK_TO_DISCOVERY_TAPPED: 'Back to Discovery Tapped',
  PRODUCT_ORDERED: 'Product Ordered',
  PRODUCT_SHOT_SWIPED: 'Product Shot Swiped',
  REDIRECTED_TO_MIXTILES_2_0: 'Redirected to Mixtiles 2.0',
  REMOVED_OLD_PHOTO: 'Removed Old Photo',
  REPLACE_CENTERPIECE_TAPPED: 'Replace Centerpiece Tapped',
  REPLACE_PHOTO_DONE_TAPPED: 'Replace Photo: Done Tapped',
  REPLACE_PHOTO_IMAGE_TAPPED: 'Replace Photo: Image Tapped',
  REPLACE_PHOTO_TAPPED: 'Replace Photo Tapped',
  COLLAGE_PHOTO_REPLACED: 'Collage Photo Replaced',
  REPLACE_PHOTO_UPLOAD_TAPPED: 'Replace Photo: Upload Tapped',
  SIGN_OUT_TAPPED: 'Sign Out Tapped',
  SIZES_DRAWER_DONE_TAPPED: 'Sizes Drawer: Done Tapped',
  SIZES_DRAWER_SIZE_TAPPED: 'Sizes Drawer: Size Tapped',
  SIZES_DRAWER_SIZE_VIEWED: 'Sizes Drawer: Size Viewed',
  SIZES_DRAWER_TAPPED: 'Sizes Drawer Tapped',
  STATIC_PHOTO_TAPPED: 'Static Photo Tapped',
  TALK_TO_US_TAPPED: 'Talk To Us Tapped',
  MATERIAL_DRAWER_TAPPED: 'Material Drawer Tapped',
  MATERIAL_DRAWER_DONE_TAPPED: 'Material Drawer: Done Tapped',
  MATERIAL_DRAWER_MATERIAL_TAPPED: 'Material Drawer: Material Tapped',
  FRAME_DRAWER_TAPPED: 'Frame Drawer Tapped',
  FRAME_DRAWER_DONE_TAPPED: 'Frame Drawer: Done Tapped',
  FRAME_DRAWER_FRAME_TAPPED: 'Frame Drawer: Frame Tapped',
  FILTER_DRAWER_TAPPED: 'Filter Drawer Tapped',
  FILTER_DRAWER_DONE_TAPPED: 'Filter Drawer: Done Tapped',
  FILTER_DRAWER_FILTER_TAPPED: 'Filter Drawer: Filter Tapped',
  MAT_DRAWER_TAPPED: 'Mat Drawer Tapped',
  MAT_DRAWER_DONE_TAPPED: 'Mat Drawer: Done Tapped',
  MAT_DRAWER_MAT_TAPPED: 'Mat Drawer: Mat Tapped',
  MAT_DRAWER_MAT_COLOR_TAPPED: 'Mat Drawer: Mat Color Tapped',
  NEW_PRODUCTS_VIEWED: 'New Products Viewed',
  OUT_OF_STOCK_SUPPRESSED: 'Out Of Stock Suppressed',
  POSITION_SWAPPED: 'Position Swapped',
  STYLE_CLICKED: 'Style Clicked',
  SWAP_POSITION_PHOTO_SELECTED: 'Swap Photo Position: Photo Selected',
  SWAP_POSITION_TAPPED: 'Swap Photo Position Tapped',
  PHOTO_SMART_CROPPED: 'Photo Smart Cropped',
  PHOTO_SMART_CROP_TIMED_OUT: 'Photo Smart Crop Timed Out',
  TILE_CROPPED: 'Tile Cropped',
  TILE_REMOVED: 'Tile Removed',
  TILE_SIZE_DRAWER_DONE_TAPPED: 'Tile Size Drawer: Done Tapped',
  TILE_SIZE_DRAWER_SIZE_TAPPED: 'Tile Size Drawer: Size Tapped',
  TILE_SIZE_DRAWER_SIZE_VIEWED: 'Tile Size Drawer: Size Viewed',
  TILE_SIZE_DRAWER_TAPPED: 'Tile Size Drawer Tapped',
  TILE_FRAME_DRAWER_TAPPED: 'Tile Frame Drawer Tapped',
  TILE_FRAME_DRAWER_DONE_TAPPED: 'Tile Frame Drawer: Done Tapped',
  TILE_FRAME_DRAWER_FRAME_TAPPED: 'Tile Frame Drawer: Frame Tapped',
  TILE_FILTER_DRAWER_TAPPED: 'Tile Filter Drawer Tapped',
  TILE_FILTER_DRAWER_DONE_TAPPED: 'Tile Filter Drawer: Done Tapped',
  TILE_FILTER_DRAWER_FILTER_TAPPED: 'Tile Filter Drawer: Filter Tapped',
  TILE_MATERIAL_DRAWER_TAPPED: 'Tile Material Drawer Tapped',
  TILE_MATERIAL_DRAWER_DONE_TAPPED: 'Tile Material Drawer: Done Tapped',
  TILE_MATERIAL_DRAWER_MATERIAL_TAPPED: 'Tile Material Drawer: Material Tapped',
  TILE_MAT_DRAWER_TAPPED: 'Tile Mat Drawer Tapped',
  TILE_MAT_DRAWER_DONE_TAPPED: 'Tile Mat Drawer: Done Tapped',
  TILE_MAT_DRAWER_MAT_COLOR_TAPPED: 'Tile Mat Drawer: Mat Color Tapped',
  TILE_MAT_DRAWER_MAT_TAPPED: 'Tile Mat Drawer: Mat Tapped',
  TILES_ORDERED: 'Tiles Ordered',
  ARTWORK_ORDERED: 'Artwork Ordered',
  TRY_NEW_PRODUCT_TAPPED: 'Try New Product Tapped',
  EXPERIMENT_ACTIVATED: 'Experiment Activated',
  UNDO_TAPPED: 'Undo Tapped',
  WALL_PANNED: 'Wall Panned',
  WALL_ZOOMED: 'Wall Zoomed',
  WALL_DESIGNER_HEIGHT_LIMIT_EXCEEDED: 'Wall Designer: Height Limit Exceeded',
  ZOOM_BUTTON_CLICKED: 'Zoom Button Clicked',
  RESTORED_PHOTO_BANK: 'Restored photo bank',
  CREATING_LOCAL_THUMBNAIL: 'Creating Local Thumbnail',
  PHOTOBOOK_FTE_PAGE_SWIPED: 'Photobook FTE Page Swiped',
  PHOTOS_RENDERED: 'Photos Rendered',
  PRICING_GENERAL_ERROR: 'Pricing General Error',
  FAILED_UPLOADS_DISPLAYED: 'Failed Uploads Displayed',
  DISCOVERY_CATEGORY_TAPPED: 'Discovery: Category Tapped',
  HOME_PAGE_BOOKS_BANNER_TAPPED: 'Homepage: Books Banner Tapped',
  HOME_PAGE_CROSS_SELL_TAPPED: 'Homepage: Cross-Sell Tapped',
  IMAGE_RENDER_FAILED: 'Image Render Failed',
  PHOTO_UPLOAD_FAILED: 'Photo Upload Failed',
  LOCAL_PHOTO_UPLOAD_FAILED: 'Local Photo Upload Failed',
  PHOTO_FACE_DETECTION_ONLINE_FAILED: 'Photo Face Detection Online Failed',
  PHOTO_FACE_DETECTION_ONLINE_FINISHED: 'Photo Face Detection Online Finished',
  RULER_TAPPED: 'Ruler Tapped',
  MIX_SIZES_POPUP_OPENED: 'Mix Sizes Popup Opened',
  MIX_SIZES_POPUP_APPROVED: 'Mix Sizes Popup Approved',
  MIX_SIZES_POPUP_DISMISSED: 'Mix Sizes Popup Dismissed',
  HOMEPAGE_MAIN_CTA_TAPPED: 'Homepage Main CTA Tapped',
  HOMEPAGE_RENDERED: 'Homepage Rendered',
  ONBOARDING_RENDERED: 'Onboarding Rendered',
  ADDRESS_ELEMENT_LOADED: 'Address Element Loaded',
  PAYMENT_ELEMENT_LOADED: 'Payment Element Loaded',
  PAYMENT_METHOD_SUBMITTED_SUCCESSFULLY:
    'Payment Method Submitted Successfully',
  SAME_ADDRESS_CHECKBOX_TAPPED: 'Same Address Checkbox Tapped',
  FACE_DETECTION_INIT_FINISHED: 'Face Detection Init Finished',
  FACE_DETECTION_INIT_FAILED: 'Face Detection Init Failed',
  FACE_EXPRESSION_DETECTION_INIT_STARTED:
    'Face Expression Detection Init Started',
  FACE_EXPRESSION_DETECTION_INIT_FINISHED:
    'Face Expression Detection Init Finished',
  ENTRYPOINT_RENDERED: 'Entrypoint Rendered',
  SMS_CONSENT_CHECKBOX_TAPPED: 'Sms Consent Checkbox Tapped',
  ATTRIBUTION_SURVEY_CHANNEL_TAPPED: 'Attribution Survey Channel Tapped',
  PHOTO_UPLOADED: 'Photo Uploaded',
  EMPTY_PHOTO_UPLOADED: 'Empty Photo Uploaded',
  PRICE_BADGE_TAPPED: 'Price Badge Tapped',
  EFFECT_FAILED: 'Effect Failed',
  LOGO_TAPPED: 'Logo Tapped',
  EXCEEDING_COLLAGE_PHOTOS_VIEW: 'Exceeding Collage Photos View',
  EXCEEDING_COLLAGE_PHOTOS_VIEW_DONE_TAPPED:
    'Exceeding Collage Photos View: Done Tapped',
  CONSENT_AUTO_ACCEPTED: 'Consent Auto Accepted',
  NO_CONSENT_REQUIRED: 'No Consent Required',
  SHOW_CONSENT_FIRST_LAYER: 'Show Consent First Layer',
  POPOVER_DISPLAYED: 'Popover Displayed',
  POPOVER_DISMISSED: 'Popover Dismissed',
  POPUP_CTA_TAPPED: 'Popup CTA Tapped',
  POPUP_DISMISSED: 'Popup Dismissed',
  POPUP_DISPLAYED: 'Popup Displayed',
  BAD_CROP_DETECTION_COMPLETED: 'Bad Crop Detection Completed',
  BAD_CROP_DETECTION_SUMMARY: 'Bad Crop Detection Summary',
  DELIVERY_PROMISE_UPDATED: 'Delivery Promise Updated',
  TILE_ROTATED: 'Tile Rotated',
  TILE_DESIGNER_ZOOMED: 'Tile Designer Zoomed',
  STRIP_3D_RENDERED: 'Strip 3D Rendered',
  SUSPECTED_CRASH: 'Suspected Crash',
  BEFORE_PHOTO_LOADING: 'Before Photo Loading',
  PHOTO_TEXTURE_LOADED: 'Photo Texture Loaded',
  BEFORE_PHOTO_MATERIAL: 'Before Photo Material',
  HOMEPAGE_VIDEO_FIRST_ATTEMPT_PLAYED: 'Video/Story First Attempt Play',
  HOMEPAGE_VIDEO_FIRST_PLAYED: 'Video/Story First Played',
  HOMEPAGE_VIDEO_PAUSED: 'Video/Story Paused',
  HOMEPAGE_VIDEO_COMPLETED: 'Video/Story Completed',
  HOMEPAGE_STORY_MUTED: 'Story Muted',
  HOMEPAGE_STORY_UNMUTED: 'Story Unmuted',
  HOMEPAGE_FAQ_TAPPED: 'FAQ - Question Tapped',
  HOMEPAGE_FOLLOW_US_TAPPED: 'Follow Us Tapped',
  HOMEPAGE_COVERED_VIEWED: "We've Got You Covered Viewed",
  HOMEPAGE_FAQ_VIEWED: 'Frequently Asked Questions Viewed',
  UPLOAD_PROGRESS_BAR_DISPLAYED: 'Upload Progress Bar Displayed',
  UPLOAD_PROGRESS_BAR_COMPLETED: 'Upload Progress Bar Completed',
  PAGE_SCROLL_HORIZONTALLY: 'Page Scroll Horizontally',
  LOW_RES_MODELS_LOADED: 'Low Res Models Loaded',
  CROP_REVIEW_INTRO_REVIEW_TAPPED: 'Crop Review: Review Photos Tapped',
  CROP_REVIEW_BACK_TAPPED: 'Crop Review: Back Tapped',
  CROP_REVIEW_NEXT_TAPPED: 'Crop Review: Next Tapped',
  CROP_REVIEW_APPROVE_ALL_TAPPED: 'Crop Review: Approve All Tapped',
  CROP_REVIEW_EDIT_ORDER_TAPPED: 'Crop Review: Edit Order Tapped',
  JOB_APPLICATION_CLICKED: 'Job Application Clicked',
  JOB_APPLICATION_SUBMITTED: 'Job Application Submitted',
  ZOOMED_WITH_PINCH: 'Zoomed with Pinch',
  APP_CLOSED: 'App Closed',
  ZOOM_CHANGED_WHILE_PANNING: 'Zoom Changed While Panning',
  APP_REFRESHED_AFTER_LONG_IDLE: 'App refreshed after long idle',
  SERVICE_WORKER_INSTALLED: 'Service Worker Installed',
  MIXTILES_PLUS_ADD_TAPPED: '‘Mixtiles +’ Add Tapped',
  MIXTILES_PLUS_REMOVE_TAPPED: '‘Mixtiles +’ Remove Tapped',
  FILES_DROPPED: 'Files Dropped',
  REFERRAL_SENT: 'Referral Sent',
  REFERRAL_REDEEMED: 'Referral Redeemed',
  SHIPPING_OPTION_CHANGED: 'Shipping Option Changed',
  CHANGE_STORE_PICKUP_TAPPED: 'Change Store Pickup Tapped',
  STORE_PICKUP_ADDRESS_CHANGED: 'Store Pickup Address Changed',
  DARK_MODE_DETECTED: 'Dark Mode Detected',
  VERTICAL_SCROLL_TO_HORIZONTAL: 'Vertical Scroll To Horizontal',
  PRODUCT_SELECTION_PRODUCT_TAPPED: 'Product Selection: Product Tapped',
}

// Should be imported only by analytics.js to prevent importing multiple modules
export const _SCREENS = {
  BLOG: 'Blog',
  BLOG_TAG: 'Blog Tag',
  BUSINESS: 'Business',
  DISCOVERY: 'Discovery',
  TILE_DESIGNER: 'Tile Designer',
  WALL_DESIGNER: 'Wall Designer',
  VOLUME_DISCOUNT: 'Volume Discount',
  CANVAS_PRODUCT_PAGE: 'Canvas Product Page',
  COLLAGE_PRODUCT_PAGE: 'Collage Product Page',
  GIFT_CARD: 'Gift Card',
  REFERRAL: 'Referral',
  HOMEPAGE: 'Pitch',
  HOMEPAGE_INDEX: 'Index',
  CROP_REVIEW_INTRO: 'Crop Review Intro',
  CROP_REVIEW: 'Crop Review',
  PHOTO_BOOKS_HOME_PAGE: 'Photo Books Homepage',
  PHOTO_BOOKS_DESIGNER: 'Photo Books Designer',
  PREFERENCES_SAVED: 'Email Preferences Change Confirmation',
  JOB_POSITION: 'Job Position',
  ART_COLLECTION_HOME_PAGE: 'Art Collection Homepage',
  ART_COLLECTION_DESIGNER: 'Art Collection Designer',
  PRODUCT_SELECTION_PAGE: 'Product Selection Page',
} as const
